<template>
  <div class="container">
    <template v-if="type == '视频'">
      <video
        class="video_view"
        ref="vueRef"
        :src="videoviewurl"
        controls
        controlslist="nodownload noplaybackrate"
        disablepictureinpicture
        @contextmenu.prevent="handleRMouse"
        width="100%"
        height="350"
      />
    </template>
    <template v-if="type == '图片'">
      <iframe class="iframe_cont" :src="imageviewurl" frameborder="0" ref="myiframe"></iframe>
    </template>
    <template v-if="type == '其他'">
      <iframe class="iframe_cont" :src="file_url" frameborder="0" ref="myiframe"></iframe>
    </template>
    <template v-if="type == 'PDF'">
      <iframe class="iframe_cont" :src="fileurl" frameborder="0" ref="myiframe"></iframe>
    </template>
    <div class="toolbar">
      <div class="full-box">
        <a class="full-btn1" @click="screen">
          <i class="el-icon-full-screen" style="font-size:17px"></i>
          <span style="margin-left:4px">全屏</span>
        </a>
      </div>
      <div class="btn-cont">
        <el-button type="info" @click="close_view">关闭</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { Base64 } from 'js-base64'
import screenfull from 'screenfull'
const request_base_path = require('@/utils/base_url')
export default {
  props: {
    viewItem: {
      type: Object,
      default: {}
    }
  },
  data () {
    return {
      filePreview: "", // 数据预览
      picturesPreview: "", // 多图预览
      fileTask: "", // 数据预埋
      fileurl: "",
      file_url: "",
      type: "",
      videoviewurl: "",
      imageviewurl: "",
      imageviewurlList: [], // 图片数据
      isImg: ["jpg", "png", "jpeg", "gif", "webp"],
      fullscreen: false, //全屏
      toolbar: false,
    }
  },
  created () {
    const systemConfig = sessionStorage.getItem('systemConfig') || ""
    const systemInfo = JSON.parse(systemConfig)
    if (systemInfo.file_preview_url) {
      this.filePreview = systemInfo.file_preview_url + "/onlinePreview?url="; // 数据预览
      this.picturesPreview = systemInfo.file_preview_url + "/picturesPreview?urls="; // 多图预览
      this.fileTask = systemInfo.file_preview_url + "/addTask?url=" // 数据预埋
    } else {
      this.filePreview = request_base_path.pro_url + "/file-preview/onlinePreview?url=" // 数据预览
      this.picturesPreview = request_base_path.pro_url + "/file-preview/picturesPreview?urls=" // 多图预览
      this.fileTask = request_base_path.pro_url + "/file-preview/addTask?url=" // 数据预埋
    }
    if (Object.keys(this.viewItem).length) {
      this.init(this.viewItem)
    }
  },
  methods: {
    handleRMouse (e) {
      e.preventDefault()
    },
    async init (item) {
      this.resetFill()
      await this.getTask(item.url)
      if (item.ext == 'mp4' || item.ext == 'avi' || item.ext == 'flv') {
        this.type = "视频"
        this.videoviewurl = item.url
        this.toolbar = false
      } else if (this.isImg.includes(item.ext)) {
        this.type = "图片"
        let len = this.imageviewurlList.length
        for (let i = 0; i < this.imageviewurlList.length; i++) {
          if (this.imageviewurlList[i] == item.url) {
            let newArr = this.imageviewurlList.splice(i, len - i)
            let oldArr = this.imageviewurlList
            this.imageviewurlList = [...newArr, ...oldArr]
            break
          }
        }
        let url = this.imageviewurlList.join("|")
        this.imageviewurl = this.picturesPreview + encodeURIComponent(Base64.encode(url))
        this.toolbar = true
      } else if (item.ext == "pdf") {
        this.type = "PDF"
        this.fileurl = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      } else {
        this.type = "其他"
        this.file_url = this.filePreview + encodeURIComponent(Base64.encode(item.url))
        this.toolbar = true
      }
    },
    resetFill () {
      this.imageviewurl = ''
      this.fileurl = ''
      this.file_url = ''
    },
    getTask (data) {
      this.fileTask + data
    },
    screen () {
      if (screenfull.isEnabled) {
        screenfull.toggle(this.$refs.myiframe)
      }
    },
    close_view () {
      this.resetFill()
      this.$emit('closeView', false)
    }
  }
};
</script>
<style lang="scss" scoped>
.full-box {
  margin-top: 10px;
  text-align: right;
  cursor: pointer;
}
.btn-cont {
  display: flex;
  justify-content: center;
}
</style>
