import request from '@/utils/request'

//修改实验课/开放实验实验报告
export function editexperimentreport(data) {
    return request({
        url: '/portal/experiment-report/edit',
        method: 'post',
        data
    })
}

//新增实验课/开放实验实验报告
export function addexperimentreport(data) {
    return request({
        url: '/portal/experiment-report/add',
        method: 'post',
        data
    })
}

//获取实验报告列表
export function getexperimentreportlist(params) {
    return request({
        url: '/portal/experiment-report/get-list',
        method: 'GET',
        params
    })
}

//获取实验报告详情
export function getexperimentreportinfo(params) {
    return request({
        url: '/portal/experiment-report/get-info',
        method: 'GET',
        params
    })
}
//删除实验报告
export function delete_report(data) {
    return request({
        url: '/portal/experiment-report/delete',
        method: 'post',
        data
    })
}