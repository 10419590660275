<template>
  <!-- 实验报告 -->
  <div class="mainpage">
    <div class="head">
      <div class="head-title">实验报告</div>
      <div class="retutnbt" @click="toback">返回</div>
    </div>
    <div class="line"></div>
    <div class="maincontain">
      <el-row class="searchcontain">
        <el-form label-width="80px">
          <el-col :span="4">
            <el-form-item label="关键字:">
              <el-input class="elinput" placeholder="请输入实验课程/实验项目/报告名称" v-model="queryParams.keyword"
                clearable></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="提交状态:">
              <el-select class="elinput" v-model="queryParams.status" placeholder="请选择">
                <el-option v-for="item in substatusoptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="批阅状态:">
              <el-select class="elinput" size="medium" v-model="queryParams.check_status" placeholder="请选择">
                <el-option v-for="item in reviewstatusoptions" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="提交时间:">
              <el-date-picker class="elinput" size="medium" v-model="timevalue" type="datetimerange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="btns">
              <el-button plain @click="handelSearch">查询</el-button>
              <el-button plain @click="handelReset">重置</el-button>
              <el-button type="primary" @click="handelAdd">新增</el-button>
            </div>
          </el-col>
        </el-form>
      </el-row>
      <div class="tipcontain" v-if="delete_flag">
        <div>
          已选择
          <span class="text">{{ selectRow.length }}</span>项
        </div>
        <div class="bt" @click="handelDelete">删除</div>
      </div>
      <el-table ref="singleTable" :data="tableData" v-loading="loading" highlight-current-row style="width: 100%"
        class="Table" stripe
        :header-cell-style="{ 'font-size': '15px', color: '#666666', 'font-weight': 'bold', background: '#F7F7F7', }"
        :row-style="{ 'font-size': '15px', color: '#222222', 'font-weight': '400', }"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="title" label="报告名称"></el-table-column>
        <el-table-column prop="course_lib_name" label="实验课程"></el-table-column>
        <el-table-column prop="experiment_name" label="实验项目"></el-table-column>
        <el-table-column prop="created_at" label="提交时间">
          <template slot-scope="scope">
            <div v-if="scope.row.status == 0">未提交</div>
            <div v-if="scope.row.status == 1">{{ scope.row.created_at | dataformat }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="check_status" label="状态">
          <template slot-scope="scope">{{ scope.row.check_status | checkstatusformat }}</template>
        </el-table-column>
        <el-table-column prop="score" label="得分">
          <template slot-scope="scope">
            <span v-if="!scope.row.check_status">暂无成绩</span>
            <span v-else>{{ scope.row.score }}</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <div class="operatcontain">
              <el-button class="opreatbt" :disabled="scope.row.id == null" @click="handelView(scope.row)">查看</el-button>
              <el-button class="opreatbt" v-if="scope.row.status === 0" @click="handelEdit(scope.row)">编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="elpagination">
        <div class="elpagination-title">共{{ total }}条</div>
        <el-pagination background layout="prev, pager, next" :total="total" :page-size="10"
          :current-page="queryParams.page" @current-change="changePage"></el-pagination>
      </div>
    </div>
    <Customdialog ref="customdialog" class="customdialog" width="50%" type="max" title="实验报告" :showclose="true">
      <div slot="dialogbody" class="dialogbody">
        <div class="bodycontain">
          <div class="topcontain">
            <div class="titletext">实验报告:</div>
            <div class="textcontent" v-html="itemdetail.content"></div>
          </div>
          <div class="bottomcontain">
            <div class="reportcomment">附件:</div>
            <div class="reporttext" v-for="(item, index) in itemdetail.file_list" :key="index">
              <span style="margin-right: 10px">附件：{{ item.name }}</span>
              <el-button type="text" @click="seeFiles(item)">查看</el-button>
            </div>
            <div class="reportcomment">报告评语:</div>
            <div class="reporttext">{{ itemdetail.remark }}</div>
            <div class="reportcomment">报告评分:</div>
            <div class="reporttext">{{ itemdetail.score }}</div>
          </div>
        </div>
      </div>
      <!-- <div slot="dialogfooter" class="dialogfooter">
        <el-button class="opreatbt" @click="">导出实验报告</el-button>
      </div>-->
    </Customdialog>
    <el-dialog title="文件预览" :visible.sync="fileVisible" width="50%" class="file-view-dialog" append-to-body>
      <!-- <FileView v-if="flag" :url="url" :type="file_type" /> -->
      <newFileView v-if="flag" :view-item="itemFile" @closeView="closeFileView" />
    </el-dialog>
  </div>
</template>

<script>
const check_status = ["未批阅", "已驳回", "已批阅"]
import dayjs from "dayjs"
import {
  getexperimentreportlist,
  getexperimentreportinfo,
  delete_report
} from "@/api/experimentreport"
import Customdialog from "@/components/customdialog.vue"
import newFileView from "@/components/newFileView.vue"
export default {
  name: "mainpage",
  components: {
    Customdialog,
    newFileView
  },
  data() {
    return {
      timevalue: "",
      total: 0,
      itemdetail: "",
      queryParams: {
        keyword: "",
        semester_id: "",
        experiment_type: 0,
        college_id: "",
        status: "",
        check_status: "",
        start_time: "",
        end_time: "",
        page: 1,
        per_page: 10
      },
      selectRow: [],
      tableData: [],
      // flag_selectRow: false,
      substatusoptions: [
        { value: 0, label: "未提交", },
        { value: 1, label: "已提交", },
      ],
      reviewstatusoptions: [
        { value: 0, label: "未批阅", },
        { value: 1, label: "已驳回", },
        { value: 2, label: "已批阅", },
      ],
      delete_flag: false,
      loading: false,
      fileVisible: false,
      flag: false,
      itemFile: {},
    }
  },
  filters: {
    checkstatusformat(val) {
      return check_status[val]
    },
    dataformat(value) {
      if (!value) return ""
      return dayjs.unix(value).format("YYYY-MM-DD HH:mm:ss")
    },
  },
  computed: {},
  created() {
    this.queryParams.semester_id = this.$route.query.semester_id
    this.queryParams.course_lib_id = this.$route.query.course_lib_id
    setTimeout(() => {
      this.getReportList()
    }, 300)
  },
  methods: {
    // 获取实验报告列表
    getReportList() {
      this.loading = true
      this.tableData = []
      let params = this.queryParams
      getexperimentreportlist(params).then((res) => {
        this.total = res.data.total
        this.tableData = res.data.data
      }).catch((error) => {
        //console.log(error)
      })
      this.loading = false
    },
    // 分页-页面
    changePage(val) {
      this.queryParams.page = val
      this.getReportList()
    },
    // 查询
    handelSearch() {
      if (this.timevalue && this.timevalue.length > 0) {
        if (this.timevalue[0]) {
          this.queryParams.start_time = dayjs(this.timevalue[0]).unix()
        }
        if (this.timevalue[1]) {
          this.queryParams.end_time = dayjs(this.timevalue[1]).unix()
        }
      } else {
        this.queryParams.start_time = ""
        this.queryParams.end_time = ""
      }
      this.getReportList()
    },
    // 重置
    handelReset() {
      this.queryParams.keyword = ""
      // this.queryParams.semester_id = "";
      this.queryParams.college_id = ""
      this.queryParams.status = ""
      this.queryParams.check_status = ""
      this.timevalue = ""
      this.queryParams.start_time = ""
      this.queryParams.end_time = ""
      this.getReportList()
    },
    // 查看
    handelView(row) {
      this.$refs.customdialog.dialogopenbt()
      let params = { id: row.id, }
      getexperimentreportinfo(params).then((response) => {
        //console.log(response, 'response')
        if (response.data) {
          this.itemdetail = response.data
        }
      }).catch((error) => {
        //console.log(error)
      })
    },
    // 编辑
    handelEdit(row) {
      this.$router.push({
        path: "/home/personalcenter/editorreport",
        query: {
          type: "编辑",
          info: encodeURIComponent(JSON.stringify(row)),
          semester_id: this.$route.query.semester_id || "",
          course_lib_id: this.$route.query.course_lib_id || "",
          course_lib_name: this.$route.query.course_lib_name || "",
        },
      })
    },
    // 新增
    handelAdd() {
      this.$router.push({
        path: "/home/personalcenter/editorreport",
        query: {
          type: "新增",
          semester_id: this.$route.query.semester_id || "",
          course_lib_id: this.$route.query.course_lib_id || "",
          course_lib_name: this.$route.query.course_lib_name || "",
        },
      })
    },
    // 删除
    handelDelete() {
      if (this.selectRow.length === 0) {
        this.$message.error('最少选中一项')
        return false
      }
      let ids = []
      this.selectRow.forEach(el => {
        ids.push(el.id)
      })
      delete_report({ id: ids.toString() }).then(res => {
        if (res.code === 0) {
          this.$message.success('删除成功')
          this.getReportList()
        }
      })
    },
    // 多选
    handleSelectionChange(row) {
      // console.log("row", row);
      this.selectRow = row
      this.delete_flag = !this.delete_flag
      // if(){}
      // this.flag_selectRow = true
    },
    // 返回
    toback() {
      this.$router.push({
        path: "/home/personalcenter/mainpage",
        query: {
          id: 2,
          componentId: "Teachingexperimentclass",
        },
      })
    },
    seeFiles(item) {
      this.itemFile = {
        url: item.url,
        ext: item.ext
      }
      this.fileVisible = true
      this.flag = false
      this.$nextTick(() => {
        this.flag = true
      })
    },
    closeFileView(data) {
      this.fileVisible = data
    },
  },
};
</script>
<style scoped lang="scss">
.mainpage {
  background: #fff;
  min-height: calc(100vh - 240px);

  .head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head-title {
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3d84ff;
      user-select: none;
      white-space: nowrap;
      margin-top: 10px;
      margin-left: 20px;
    }

    .retutnbt {
      margin-right: 40px;
      width: 64px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background: #3d84ff;
      border-radius: 4px;
      color: white;
      user-select: none;
      cursor: pointer;
      margin-top: 10px;
      font-size: 14px;
    }
  }

  .line {
    margin-top: 14px;
    margin-bottom: 4px;
    border-bottom: 2px solid #cccccc;
  }

  .maincontain {
    background: #ffffff;
    border-radius: 4px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;

    .searchcontain {
      background: #fcfcfc;
      border: 1px solid #ececec;
      padding: 10px 20px;

      .el-form-item {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      .elinput {
        width: 100%;
      }

      .btns {
        text-align: right;
      }
    }

    .newadd {
      margin-top: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .bt {
        width: 64px;
        height: 38px;
        line-height: 38px;
        text-align: center;
        background: #ffffff;
        border: 1px solid #3d84ff;
        border-radius: 2px;
        user-select: none;
        cursor: pointer;
        font-size: 14px;
      }

      .restbt {
        margin-right: 20px;
        background: #3d84ff;
        color: white;
      }
    }

    .tipcontain {
      margin-top: 10px;
      height: 40px;
      background-color: rgb(230, 247, 255);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 10px;
      padding-right: 40px;

      .text {
        font-size: 18px;
        color: #3d84ff;
        padding-left: 5px;
        padding-right: 5px;
      }

      .bt {
        border: none;
        background: none;
        margin: 0px;
        padding: 0px;
        color: #3d84ff;
        user-select: none;
        cursor: pointer;
      }
    }

    .Table {
      width: 100%;

      .operatcontain {
        display: flex;
        justify-content: center;
        align-items: center;

        .opreatbt {
          border: none;
          background: none;
          margin: 10px;
          padding: 0px;

          color: #3d84ff;
        }
      }
    }

    .elpagination {
      margin-top: 20px;
      margin-bottom: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .elpagination-title {
        margin-right: 4px;
      }
    }
  }

  .customdialog {
    .dialogbody {
      .bodycontain {
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .topcontain {
          flex: 1;
          width: 100%;

          .titletext {
            margin-top: 20px;
            margin-left: 20px;
            font-size: 20px;
            font-weight: 400;
            color: #222222;
          }

          .textcontent {
            margin-left: 20px;
            margin-right: 20px;
          }
        }

        .bottomcontain {
          height: 150px;
          width: 100%;

          .reportcomment {
            margin-top: 10px;
            margin-left: 20px;
            font-size: 20px;
            font-weight: 400;
            color: #222222;
          }

          .reporttext {
            margin-top: 10px;
            margin-left: 30px;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
          }
        }
      }

      .title {
        margin-top: 20px;
        font-size: 24px;
        text-align: center;
        font-weight: bold;
        color: #3a3a3a;
      }

      .line {
        margin-top: 14px;
        border-bottom: 2px solid #cccccc;
      }

      .text1 {
        margin-left: 80px;
        margin-top: 10px;
        font-size: 20px;
        font-weight: 500;
        color: #3a3a3a;

        span {
          margin-left: 10px;
          font-size: 24px;
          font-weight: bold;
        }
      }

      .text2 {
        margin-left: 20px;
        margin-top: 15px;
        font-size: 20px;
        font-weight: 500;
        color: #3a3a3a;
      }
    }

    .dialogfooter {
      border-top: 1px solid #d6d6d6;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .opreatbt {
        margin-right: 60px;
        width: 140px;
        height: 42px;
        background: #3d84ff;
        border-radius: 4px;
        font-size: 16px;

        font-weight: 400;
        color: #ffffff;
      }
    }
  }
}

.btgroup {
  color: #26b5ff;
  height: 60px;
  width: 100%;

  .bt {
    margin-top: 10px !important;
    width: 100%;
    height: 20px;
    margin: 0px;
    padding: 0px;
    border: none;
  }
}

::v-deep .el-button--primary {
  background-color: #3d84ff;
  border-color: #3d84ff;
}
</style>
